import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

import { Row, Col, Typography, Spin } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Header from "./components/Header";
import AlertMessage from "./components/alert";
import FileDetails from "./components/FileDetails";

const Results = () => {
  const navigate = useNavigate();
  const { Title } = Typography;

  const [alertBox, setAlertBox] = useState(false);
  const [Message, setMessage] = useState("");
  const [currentKey, setCurrentKey] = useState(0); // currentKey is initialized to 0.
  const [currentFileDetails, setCurrentFileDetails] = useState([]);
  const [processingCompleted, setProcessingCompleted] = useState(false);
  const [fileNumber, setFileNumber] = useState(1);

  const [endDisabled, setEndDisabled] = useState(false);
  const [startDisabled, setStartDisabled] = useState(false);

  const [fileInfo, setFileInfo] = useState({});

  const [processedData, setProcessedData] = useState({});
  let total_number_of_documents;
  let total_number_of_documents_instance_created;
  let total_number_of_documents_successfully_processed;
  let total_number_of_documents_failed_to_process;

  const location = useLocation();
  const folderName = location.state?.folderName;

  const onCancel = () => {
    setAlertBox(false);
    navigate("/upload");
  };

  const handleLogoClick = () => {
    navigate("/upload");
  };

  const incrementKey = () => {
    const index = currentKey + 1;
    if (currentKey !== processedData.length - 1) {
      setCurrentKey(index);
      setFileNumber(index + 1);
      // setEndDisabled(false);
    } 
    // else {
    //   setStartDisabled(true);
    // }
  };

  const decrementKey = () => {
    const index = currentKey - 1;
    if (currentKey !== 0) {
      setCurrentKey(index);
      setFileNumber(index + 1);
      // setStartDisabled(false);
    } 
    // else {
    //   setEndDisabled(true);
    // }
  };

  let succeded_data = [];

  const fetchData = async () => {
    try {
      const formData = new FormData();
      formData.append("foldername", folderName);
      const response = axios.post(
        "http://54.158.196.125:8000/api/payslip/view-all-files",
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
          processData: false,
          contentType: false,
        }
      );
      const data = (await response).data;

      // Copy the original succeeded data to a new array
      let unique_succeeded_data = [...succeded_data];

      // Filter out the entries that have already been added to unique_succeeded_data
      const filtered_data = data.payslip_info
        .filter(
          (payslip) =>
            payslip.jobstatus === "SUCCEEDED" ||
            payslip.jobstatus === "NOT ACCEPTED"
        )
        .filter(
          (payslip) =>
            !unique_succeeded_data.find((entry) => entry.id === payslip.id)
        );
      if (filtered_data.length > 0) {
        succeded_data = [...unique_succeeded_data, ...filtered_data];
      }

      total_number_of_documents = data.total_number_of_documents;
      total_number_of_documents_instance_created =
        data.total_number_of_documents_instance_created;
      total_number_of_documents_successfully_processed =
        data.total_number_of_documents_successfully_processed;
      total_number_of_documents_failed_to_process =
        data.total_number_of_documents_failed_to_process;

      // Check if data has changed in succceded_data
      if (
        succeded_data.length > 0 &&
        succeded_data.length !== unique_succeeded_data.length
      ) {
        setProcessedData(succeded_data);
        // console.log("Processed Data: ", processedData);
        toast.success(
          `Processed ${total_number_of_documents_successfully_processed} documents successfully`,
          {
            autoClose: 2000,
            position: toast.POSITION.TOP_RIGHT,
            className: "custom-toast",
          }
        );
      }

      if (
        total_number_of_documents ===
          total_number_of_documents_instance_created &&
        total_number_of_documents_instance_created ===
          total_number_of_documents_successfully_processed +
            total_number_of_documents_failed_to_process
      ) {
        // console.log("Completed");
        setProcessingCompleted(true);
        if (total_number_of_documents_failed_to_process > 0) {
          toast.error(
            `Failed to process ${total_number_of_documents_failed_to_process} documents `,
            {
              autoClose: 2000,
              position: toast.POSITION.TOP_RIGHT,
              className: "custom-toast",
            }
          );
        }
        toast.success("Processing completed", {
          autoClose: 2000,
          position: toast.POSITION.TOP_RIGHT,
          className: "custom-toast",
        });
      } else if (!processingCompleted) {
        setTimeout(fetchData, 5000); // Call the API again after 5 seconds
      }
      setFileInfo({
        "Number of documents": total_number_of_documents,
        "Documents with successful text extraction":
          total_number_of_documents_successfully_processed,
        "Documents with failed text extraction":
          total_number_of_documents_failed_to_process,
      });
    } catch (err) {
      setAlertBox(true);
      setMessage(err.message);
    }
  };

  useEffect(() => {
    if (folderName && !processingCompleted) {
      fetchData();
    }
  }, [folderName, processingCompleted]);

  useEffect(() => {
    if (!folderName) {
      setAlertBox(true);
      setMessage("Please provide the details for extraction.");
    } else if (processedData.length !== 0) {
      // console.log("processedData: ", processedData);
      setCurrentFileDetails(processedData[currentKey]);
    
      if (processedData.length === 1) {
        setStartDisabled(true)
        setEndDisabled(true)
      }
      else {
        if (currentKey === 0) {
          setStartDisabled(true)
          setEndDisabled(false)
        }
      
        else if (currentKey === processedData.length - 1) {
          setEndDisabled(true)
          setStartDisabled(false)
        }
        else {
          setStartDisabled(false)
          setEndDisabled(false)
        }
      }
    }

  }, [processedData, currentKey, folderName]);

  return (
    <div className="w-full h-screen overflow-hidden">
      <Header onLogoClick={processingCompleted ? handleLogoClick : undefined} />
      <ToastContainer />
      {alertBox ? <AlertMessage message={Message} onCancel={onCancel} /> : ""}
      {currentFileDetails && Object.keys(currentFileDetails).length > 0 ? (
        <FileDetails
          result={currentFileDetails}
          processing={processingCompleted}
          incrementKey={incrementKey}
          decrementKey={decrementKey}
          fileNumber={fileNumber}
          fileInfo={fileInfo}
          buttonDisabled={{ startDisabled, endDisabled }}
        />
      ) : (
        <div>
          <Row justify="center">
            <Col
              style={{ textAlign: "center", padding: "10%", color: "#db0f0f" }}
            >
              <Spin size="large" />
            </Col>
          </Row>
        </div>
      )}
      <Row justify="center">
        <Title level={4} style={{ color: "#09488a" }}></Title>
      </Row>
    </div>
  );
};
export default Results;
