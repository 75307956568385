import React from "react";
import axios from "axios";
import Icons from "../assets/icons";

const ExcelDownloadButton = ({ apiEndpoint, fileName, text }) => {
  const handleDownload = () => {
    axios
      .get(apiEndpoint, {
        responseType: "blob",
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = fileName;
        anchor.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Failed to download Excel file:", error);
      });
  };

  return (
    <div className="w-full pl-8 ">
      <button className="flex items-center py-4.5 px-7 rounded-lg bg-red200 text-white export-button ml-auto" onClick={handleDownload}>
        <h2 className="mr-2 font-semibold text-base leading-[19px]">
          {text}
        </h2>
        <Icons.ExportIcon />
      </button>
    </div>
  );
};

export default ExcelDownloadButton;
