import React, { useState } from "react";
import axios from "axios";
import AWS from "aws-sdk";
import { useNavigate } from "react-router-dom";
import { Button, Input, Spin } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ModalTextNumberInput from "./components/ModalTextNumberInput";
import ModalTextInput from "./components/ModalTextInput";
import Header from "./components/Header";
import icons from "./assets/icons";
import AlertMessage from "./components/alert";

import configData from "./config.json";

const FileUpload = () => {
  const navigate = useNavigate();

  const S3_BUCKET = configData.S3_BUCKET;
  const REGION = configData.REGION;

  AWS.config.update({
    accessKeyId: configData.accessKeyId,
    secretAccessKey: configData.secretAccessKey,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [alertBox, setAlertBox] = useState(false);
  const [upload, setUpload] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [readyForUpload, setReadyForUpload] = useState(false);
  const [defaultConditions, setDefaultConditions] = useState({
    PAY_TO_DATE: false,
    CURRENT_GROSS_PAY: false,
    YTD_GROSS_PAY: false,
    CURRENT_NET_PAY: false,
    YTD_NET_PAY: false,
    REGULAR_HOURLY_RATE: false,
    CURRENT_REGULAR_PAY: false,
    YTD_REGULAR_PAY: false,
  });

  const [values, setValues] = useState({});
  const [service, setService] = useState("custom");

  const [extractableText, setExtractableText] = useState([]);
  const [Message, setMessage] = useState("");

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [progress, setProgress] = useState(false);

  const validateFile = (file) => {
    const validTypes = [
      "application/pdf",
      "image/jpeg",
      "image/png",
      "image/jpeg",
    ];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const openModal2 = () => {
    setModal2Open(true);
  };

  const closeModal2 = () => {
    setModal2Open(false);
  };

  const handleButtonClick = (name) => {
    setValues((prevValues) => ({
      ...prevValues,
      [name]: 0,
    }));

    setDefaultConditions((prevValues) => ({
      ...prevValues,
      [name]: true,
    }));
  };

  const handleModalSave = (name, value) => {
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleExtractModalSave = (name) => {
    setExtractableText((prevValues) => ({ ...prevValues, [name]: 0 }));
  };

  const handleInputChange = (name, value) => {
    setValues((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });
  };

  const generateFolderName = () => {
    const timestamp = new Date().getTime();
    const randomNum = Math.floor(Math.random() * 1000000);
    const name = `folder_${timestamp}_${randomNum}`;
    return name.trim();
  };

  const handleFileChange = (event) => {
    if (event.target.files) {
      setReadyForUpload(true);
      let count = 0;
      const filesArray = Array.from(event.target.files).map((file) => file);

      for (let i = 0; i < filesArray.length; i += 1) {
        if (validateFile(filesArray[i])) {
          setSelectedFiles((prevArray) => [...prevArray, filesArray[i]]);
          count += 1;
        } else {
          filesArray[i].invalid = true;
        }
      }
      toast.info(`${count} files selected`, {
        autoClose: 2000,
        position: toast.POSITION.TOP_RIGHT,
        className: "custom-toast",
      });
    }
  };

  const uploadFile = (file, randomstr, foldername, fileNumber, totalFiles) => {
    // console.log("Entered upload File");
    const subfolder = "files/payslip";

    const params = {
      ACL: "private",
      Body: file,
      Bucket: S3_BUCKET,
      Key: `${subfolder}/${foldername}/${randomstr}`,
      ContentType: file.type,
    };

    myBucket
      .putObject(params)
      // .on("httpUploadProgress", () => {
      //   setProgress(Math.round((fileNumber / totalFiles) * 100));
      // })
      .send(async (err) => {
        if (err) {
          console.log(err);
          setAlertBox(true);
          setMessage(err.message);
        } else {
          if (fileNumber === totalFiles) {
            setUpload(true);
            setProgress(false);
            setSelectedFiles([]);
            toast.success("Successfully uploaded", {
              autoClose: 2000,
              position: toast.POSITION.TOP_RIGHT,
              className: "custom-toast",
            });
          }
        }
      });
  };

  const clearFile = (event) => {
    setUpload(false);
    setSelectedFiles([]);
    setReadyForUpload(false);
    if (event) {
      event.target.value = null;
    }
  };

  const handleFileSubmit = () => {
    const foldername = generateFolderName();
    for (let i = 0; i < selectedFiles.length; i += 1) {
      setProgress(true);
      const randomstr = `${
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15)
      }.${selectedFiles[i].name.split(".").pop()}`;
      uploadFile(
        selectedFiles[i],
        randomstr,
        foldername,
        i + 1,
        selectedFiles.length
      );
      setFolderName(foldername);
    }
  };

  const handleRemove = (name) => {
    const updatedValues = { ...values };
    const updatedConditions = { ...defaultConditions, [name]: false };

    delete updatedValues[name];
    setValues(updatedValues);
    setDefaultConditions(updatedConditions);
  };

  const handleRemoveWord = (name) => {
    const updatedWords = { ...extractableText };
    delete updatedWords[name];
    setExtractableText(updatedWords);
  };

  const handleServiceChange = (service) => {
    setService(service);
  };

  const onCancel = () => {
    setAlertBox(false);
    clearFile();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("foldername", folderName);
    formData.append("service", service);
    formData.append("conditions", JSON.stringify(values));
    formData.append("words", JSON.stringify(extractableText));

    axios
      .post(
        "http://54.158.196.125:8000/api/payslip/bulk-upload-batch-processing",
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
          processData: false,
          contentType: false,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          navigate("/details", { state: { folderName: folderName} });
        }
      })
      .catch((err) => {
        setAlertBox(true);
        setMessage(err.message);
        // console.log(alertBox);
      });
  };

  return (
    <div className="h-screen overflow-hidden">
      {alertBox ? <AlertMessage message={Message} onCancel={onCancel} /> : ""}
      <Header />
      <ToastContainer />
      <div className="bg-primaryLight py-8 px-14 w-full h-[calc(100%-210px)] overflow-auto max-w-full">
        <div className=" max-w-[1254px] ml-auto mr-auto">
          <div className="mb-8">
            <h2 className="text-black100 font-bold text-2xl leading-[29px] mb-2">
              Payslip Insights Generator
            </h2>
            <p className="font-normal text-base text-grey100 max-w-[447px]">
              To perform text extraction on a payslip and apply rules to predict
              if eligible for availing loan
            </p>
          </div>
          <div className="w-full h-full p-6 bg-white border-border100 border border-solid rounded-xl shadow-cardShadow mb-[16px]">
            <h2 className="font-semibold text-lg leading-5.5 text-black100 mb-[16px]">
              Select Service
            </h2>
            <div className="w-[285px] p-1 bg-red-100 rounded-lg shadow-shadow2 ">
              <div
                className="btn-group btn-group-toggle w-full custom-buttons "
                data-toggle="buttons"
              >
                <label
                  className="btn btn-primary w-full bg-none border-none"
                  onClick={() => handleServiceChange("textract")}
                >
                  <input
                    type="radio"
                    name="option1"
                    id="textract"
                    value="textract"
                  />{" "}
                  Textract
                </label>
                <label
                  className="btn btn-primary active w-full bg-none border-none"
                  onClick={() => handleServiceChange("custom")}
                >
                  <input
                    type="radio"
                    name="option1"
                    id="custom"
                    value="custom"
                  />{" "}
                  Custom
                </label>
              </div>
            </div>
          </div>

          <div className="w-full h-full p-6 bg-white border-border100 border border-solid rounded-xl shadow-cardShadow mb-[16px]">
            <h2 className="font-semibold text-lg leading-5.5 text-black100 mb-[16px]">
              Upload Payslip
            </h2>
            <div className="flex items-center">
              <div className="bg-white border border-solid border-border100 p-4 rounded shadow-cardShadow flex items-center">
                <div className="flex items-center">
                  <icons.UploadIcon />
                  <h2 className="text-base leading-6 font-medium text-gray200 ml-2">
                    Upload Payslip -
                  </h2>
                </div>
                <div className="ml-1">
                  <label
                    htmlFor="customFile"
                    className="mb-0 cursor-pointer border border-solid !border-red200 p-2 text-sm leading-[17px] text-red200 font-semibold rounded"
                  >
                    Choose File
                  </label>
                  <input
                    type="file"
                    multiple
                    accept=".pdf,.jpeg,.png,.jpg"
                    onChange={handleFileChange}
                    onClick={clearFile}
                    id="customFile"
                    className="hidden"
                  />
                  <button
                    className={`mb-0 cursor-pointer border border-solid !border-red200 p-2 ml-2 text-sm leading-[17px] text-red200 font-semibold rounded ${!readyForUpload ? 'opacity-50' : ''}`}
                    onClick={handleFileSubmit}
                    disabled = {!readyForUpload}
                  >
                    Upload Files
                  </button>
                </div>
              </div>

              <div className="flex items-center ml-4">
                {progress && <Spin tip={`Uploading...`} />}
                {upload && <icons.PdfIcon />}
              </div>
            </div>
          </div>

          <div className="w-full h-full p-6 bg-white border-border100 border border-solid rounded-xl shadow-cardShadow mb-[16px]">
            <h2 className="font-semibold text-lg leading-5.5 text-black100 mb-[16px]">
              Enter Minimum Required Values
            </h2>
            <div className="flex items-center flex-wrap gap-2 pb-6">
              {Object.entries(defaultConditions)?.map(
                ([name, isSelected], index) => (
                  <Button
                    type="primary"
                    key={name}
                    onClick={() => handleButtonClick(name)}
                    className={`${
                      isSelected
                        ? "bg-red400 !text-black100"
                        : "bg-red300 text-grey200"
                    } py-2 px-3 border border-solid border-border200 rounded-lg shadow-none flex items-center justify-center h-full text-xs leading-6 font-medium  hover:!bg-red400 hover:!text-black100  value-add-buttons`}
                  >
                    {name}
                  </Button>
                )
              )}

              <Button
                type="primary"
                onClick={openModal}
                className="bg-white py-2 px-3 flex items-center h-full shadow-none border border-solid !border-red200 text-xs leading-6 font-medium text-red200  rounded-lg hover:!text-red200 "
              >
                <icons.AddIcon />
                <h2 className="ml-2">ADD CUSTOM</h2>
              </Button>
            </div>
            <ModalTextNumberInput
              isOpen={modalOpen}
              onClose={closeModal}
              onSave={handleModalSave}
            />

            <div>
              {Object.keys(values).length !== 0 ? (
                <div className="border-t border-solid border-border100 pt-6 grid grid-cols-4 gap-5 input-grids">
                  {Object.entries(values).map(([name, value], index) => (
                    <div
                      key={name}
                      className="border-r border-solid border-border100 pr-5 input-card"
                    >
                      <h2 className="text-xs leading-6 font-medium text-black100 mb-[5px]">
                        {name}
                      </h2>
                      <div className="flex items-center">
                        <Input
                          type="number"
                          value={value}
                          onChange={(e) =>
                            handleInputChange(name, e.target.value)
                          }
                          min={0}
                          className="border-[1.5px] border-solid border-white200 p-2 text-xs leading-6 text-grey200 hover:!border-black100 hover:!shadow-none focus:!border-black100 focus:!shadow-none"
                        />
                        <Button
                          type="danger"
                          onClick={() => handleRemove(name)}
                          className="delete-button border-none w-4 h-4 p-0 ml-3.5 focus:outline-none"
                        >
                          <icons.TrashIcon />
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
          <div className="w-full h-full p-6 bg-white border-border100 border border-solid rounded-xl shadow-cardShadow mb-[16px]">
            <h2 className="font-semibold text-lg leading-5.5 text-black100 mb-[16px]">
              Enter Word To Extract
            </h2>
            <Button
              type="primary"
              onClick={openModal2}
              className="bg-white py-2 px-3 flex items-center h-full shadow-none border border-solid !border-red200 text-xs leading-6 font-medium text-red200  rounded-lg hover:!text-red200 focus:!outline-none "
            >
              <icons.AddIcon />
              <h2 className="ml-2">ADD WORD</h2>
            </Button>

            <ModalTextInput
              isOpen={modal2Open}
              onClose={closeModal2}
              onSave={handleExtractModalSave}
            />

            {Object.keys(extractableText).length !== 0 ? (
              <div className="flex items-center w-full flex-wrap gap-2 mt-[16px]">
                {Object.entries(extractableText).map(([name, value], index) => (
                  <div
                    key={value}
                    className="border border-solid !border-border300 bg-red500 rounded-[30px] flex items-center py-1.5 px-4"
                  >
                    <h2 className="font-medium text-xs leading-6 text-black100 mr-2 pt-[2px]">
                      {name}
                    </h2>
                    <Button
                      type="danger"
                      onClick={() => handleRemoveWord(name)}
                      className="delete-button border-none w-4 h-4 p-0 ml-3.5 focus:outline-none"
                    >
                      <icons.TrashIcon />
                    </Button>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          <div className="pt-8 pb-8 bg-primaryLight fixed bottom-0 w-full left-0">
            <div className="max-w-[1254px] ml-auto mr-auto pr-[17px]">
              <button
                type="submit"
                id="submit-button"
                className={`w-[190px] py-4 bg-red200 rounded-lg text-white text-base leading-[19px] font-semibold h-[51px] flex items-center justify-center float-right submit-button ${!upload ? 'opacity-50' : ''}`}
                onClick={handleSubmit}
                disabled={!upload}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FileUpload;
