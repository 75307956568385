import React, { useState } from "react";
import { Modal, Input, Button } from "antd";

const ModalTextNumberInput = ({ isOpen, onClose, onSave }) => {
  const [inputValue, setInputValue] = useState(0);
  const [selectedName, setSelectedName] = useState("");

  const handleNameChange = (value) => {
    setSelectedName(value);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSave = () => {
    onSave(selectedName, parseFloat(inputValue));
    setSelectedName("");
    setInputValue(0);
    onClose();
  };

  const handleClose = () => {
    setSelectedName("");
    setInputValue(0);
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      footer={null}
      title="Add Condition"
      centered
      className="Modal-container"
    >
      <div className="px-6 pt-[14px] pb-[17px]">
        <div className="grid grid-cols-2 gap-4  mb-[42px]">
          <div>
            <label
              htmlFor="condition"
              className="mb-[5px] text-xs leading-6 font-medium text-black100"
            >
              Condition
            </label>
            <Input
              value={selectedName}
              id="condition"
              autoComplete="off"
              onChange={(e) => handleNameChange(e.target.value.toUpperCase())}
              className="border-[1.5px] border-solid border-white200 p-2 text-xs leading-6 text-grey200 hover:!border-black100 hover:!shadow-none focus:!border-black100 focus:!shadow-none"
            />
          </div>
          <div>
            <label
              htmlFor="condition"
              className="mb-[5px] text-xs leading-6 font-medium text-black100"
            >
              Value
            </label>
            <Input
              type="number"
              id="value"
              value={inputValue}
              onChange={handleInputChange}
              autoComplete="off"
              min={0}
              className="border-[1.5px] border-solid border-white200 p-2 text-xs leading-6 text-grey200 hover:!border-black100 hover:!shadow-none focus:!border-black100 focus:!shadow-none"
            />
          </div>
        </div>

        <div className="flex items-start justify-end">
          <Button
            onClick={handleClose}
            className="py-2 !px-5 bg-white font-normal text-sm leading-[17px] text-black100 rounded border border-solid border-border400 h-full mr-[8px] hover:!text-black100 hover:!border-black100"
          >
            Close
          </Button>
          <Button
            onClick={handleSave}
            className="py-2 !px-5 bg-black font-normal text-sm leading-[17px] text-white rounded h-full hover:!text-white hover:!border-transparent"
            disabled={selectedName === ""}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalTextNumberInput;
