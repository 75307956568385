import React from "react";
const UploadIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0001 19.4582C5.05841 19.4582 1.04175 15.4415 1.04175 10.4998C1.04175 5.55817 5.05841 1.5415 10.0001 1.5415C14.9417 1.5415 18.9584 5.55817 18.9584 10.4998C18.9584 15.4415 14.9417 19.4582 10.0001 19.4582ZM10.0001 2.7915C5.75008 2.7915 2.29175 6.24984 2.29175 10.4998C2.29175 14.7498 5.75008 18.2082 10.0001 18.2082C14.2501 18.2082 17.7084 14.7498 17.7084 10.4998C17.7084 6.24984 14.2501 2.7915 10.0001 2.7915Z"
        fill="#606060"
      />
      <path
        d="M10 14.0415C9.65833 14.0415 9.375 13.7582 9.375 13.4165V8.4165C9.375 8.07484 9.65833 7.7915 10 7.7915C10.3417 7.7915 10.625 8.07484 10.625 8.4165V13.4165C10.625 13.7582 10.3417 14.0415 10 14.0415Z"
        fill="#606060"
      />
      <path
        d="M12.5001 10.7084C12.3418 10.7084 12.1834 10.65 12.0584 10.525L10.0001 8.4667L7.94178 10.525C7.70011 10.7667 7.30011 10.7667 7.05845 10.525C6.81678 10.2834 6.81678 9.88337 7.05845 9.6417L9.55845 7.1417C9.80011 6.90003 10.2001 6.90003 10.4418 7.1417L12.9418 9.6417C13.1834 9.88337 13.1834 10.2834 12.9418 10.525C12.8168 10.65 12.6584 10.7084 12.5001 10.7084Z"
        fill="#606060"
      />
    </svg>
  );
};
const PdfIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.0999 2.07178L29.6639 7.87178V29.9278H8.87891V29.9998H29.7349V7.94478L24.0999 2.07178Z"
        fill="#909090"
      />
      <path
        d="M24.0311 2H8.80811V29.928H29.6641V7.873L24.0311 2Z"
        fill="#F4F4F4"
      />
      <path
        d="M8.65489 3.5H2.26489V10.327H22.3649V3.5H8.65489Z"
        fill="#7A7B7C"
      />
      <path
        d="M22.472 10.2109H2.39502V3.37891H22.472V10.2109Z"
        fill="#DD2025"
      />
      <path
        d="M9.05212 4.53418H7.74512V9.33418H8.77312V7.71518L9.00012 7.72818C9.22069 7.72439 9.43919 7.68488 9.64712 7.61118C9.82942 7.54848 9.99713 7.44949 10.1401 7.32018C10.2856 7.197 10.4003 7.04154 10.4751 6.86618C10.5754 6.57468 10.6112 6.26488 10.5801 5.95818C10.5739 5.73909 10.5355 5.52211 10.4661 5.31418C10.403 5.16409 10.3093 5.02878 10.191 4.91688C10.0728 4.80497 9.93247 4.71891 9.77912 4.66418C9.64653 4.61618 9.50953 4.58135 9.37012 4.56018C9.26454 4.5439 9.15794 4.53521 9.05112 4.53418M8.86212 6.82818H8.77312V5.34818H8.96612C9.0513 5.34204 9.13677 5.35512 9.21622 5.38645C9.29566 5.41778 9.36706 5.46656 9.42512 5.52918C9.54544 5.6902 9.60971 5.88619 9.60812 6.08718C9.60812 6.33318 9.60812 6.55618 9.38612 6.71318C9.22618 6.80115 9.04414 6.84175 8.86212 6.82818ZM12.5331 4.52118C12.4221 4.52118 12.3141 4.52918 12.2381 4.53218L12.0001 4.53818H11.2201V9.33818H12.1381C12.4889 9.3478 12.8382 9.28834 13.1661 9.16318C13.43 9.05851 13.6637 8.88976 13.8461 8.67218C14.0235 8.45264 14.1508 8.19702 14.2191 7.92318C14.2977 7.61305 14.336 7.2941 14.3331 6.97418C14.3525 6.59634 14.3233 6.21757 14.2461 5.84718C14.1729 5.57455 14.0358 5.32328 13.8461 5.11418C13.6973 4.94535 13.5152 4.80915 13.3111 4.71418C13.1359 4.6331 12.9516 4.57334 12.7621 4.53618C12.6868 4.52374 12.6105 4.51805 12.5341 4.51918M12.3521 8.45618H12.2521V5.39218H12.2651C12.4713 5.36846 12.6799 5.40566 12.8651 5.49918C13.0008 5.60751 13.1113 5.74398 13.1891 5.89918C13.2731 6.06252 13.3215 6.24179 13.3311 6.42518C13.3401 6.64518 13.3311 6.82518 13.3311 6.97418C13.3352 7.14582 13.3241 7.31748 13.2981 7.48718C13.2673 7.66141 13.2103 7.82998 13.1291 7.98718C13.0372 8.13334 12.913 8.25649 12.7661 8.34718C12.6427 8.42698 12.4966 8.46421 12.3501 8.45318M17.4301 4.53818H15.0001V9.33818H16.0281V7.43418H17.3281V6.54218H16.0281V5.43018H17.4281V4.53818"
        fill="#464648"
      />
      <path
        d="M21.7809 20.255C21.7809 20.255 24.9689 19.677 24.9689 20.766C24.9689 21.855 22.9939 21.412 21.7809 20.255ZM19.4239 20.338C18.9174 20.4499 18.4238 20.6138 17.9509 20.827L18.3509 19.927C18.7509 19.027 19.1659 17.8 19.1659 17.8C19.6432 18.6033 20.1986 19.3577 20.8239 20.052C20.3524 20.1223 19.885 20.2185 19.4239 20.34V20.338ZM18.1619 13.838C18.1619 12.889 18.4689 12.63 18.7079 12.63C18.9469 12.63 19.2159 12.745 19.2249 13.569C19.1471 14.3976 18.9736 15.2143 18.7079 16.003C18.3441 15.3408 18.1557 14.5966 18.1609 13.841L18.1619 13.838ZM13.5129 24.354C12.5349 23.769 15.5639 21.968 16.1129 21.91C16.1099 21.911 14.5369 24.966 13.5129 24.354ZM25.8999 20.895C25.8899 20.795 25.7999 19.688 23.8299 19.735C23.0088 19.7218 22.1881 19.7797 21.3769 19.908C20.5912 19.1164 19.9146 18.2235 19.3649 17.253C19.7112 16.2524 19.9208 15.2097 19.9879 14.153C19.9589 12.953 19.6719 12.265 18.7519 12.275C17.8319 12.285 17.6979 13.09 17.8189 14.288C17.9375 15.0931 18.161 15.8791 18.4839 16.626C18.4839 16.626 18.0589 17.949 17.4969 19.265C16.9349 20.581 16.5509 21.271 16.5509 21.271C15.5737 21.5891 14.6537 22.0618 13.8259 22.671C13.0019 23.438 12.6669 24.027 13.1009 24.616C13.4749 25.124 14.7839 25.239 15.9539 23.706C16.5756 22.9142 17.1435 22.0817 17.6539 21.214C17.6539 21.214 19.4379 20.725 19.9929 20.591C20.5479 20.457 21.2189 20.351 21.2189 20.351C21.2189 20.351 22.8479 21.99 24.4189 21.932C25.9899 21.874 25.9139 20.993 25.9039 20.897"
        fill="#DD2025"
      />
      <path
        d="M23.9541 2.07715V7.95015H29.5871L23.9541 2.07715Z"
        fill="#909090"
      />
      <path d="M24.031 2V7.873H29.664L24.031 2Z" fill="#F4F4F4" />
      <path
        d="M8.97497 4.45704H7.66797V9.25704H8.69997V7.63904L8.92797 7.65204C9.14854 7.64824 9.36704 7.60873 9.57497 7.53504C9.75726 7.47231 9.92496 7.37332 10.068 7.24404C10.2124 7.12053 10.326 6.96509 10.4 6.79004C10.5003 6.49853 10.5361 6.18874 10.505 5.88204C10.4987 5.66294 10.4603 5.44596 10.391 5.23804C10.3278 5.08794 10.2342 4.95264 10.1159 4.84073C9.9976 4.72882 9.85732 4.64276 9.70397 4.58804C9.57077 4.53957 9.4331 4.5044 9.29297 4.48304C9.18739 4.46675 9.08079 4.45806 8.97397 4.45704M8.78497 6.75104H8.69597V5.27104H8.88997C8.97515 5.26489 9.06062 5.27797 9.14007 5.3093C9.21952 5.34063 9.29091 5.38941 9.34897 5.45204C9.46929 5.61305 9.53356 5.80904 9.53197 6.01004C9.53197 6.25604 9.53197 6.47904 9.30997 6.63604C9.15004 6.724 8.96799 6.76361 8.78597 6.75004M12.456 4.44404C12.345 4.44404 12.237 4.45204 12.161 4.45504L11.926 4.46104H11.146V9.26104H12.064C12.4148 9.27065 12.7641 9.21119 13.092 9.08604C13.3559 8.98136 13.5896 8.81262 13.772 8.59504C13.9493 8.37549 14.0766 8.11987 14.145 7.84604C14.2235 7.53591 14.2618 7.21695 14.259 6.89704C14.2784 6.51919 14.2491 6.14042 14.172 5.77004C14.0988 5.4974 13.9616 5.24613 13.772 5.03704C13.6232 4.8682 13.441 4.73201 13.237 4.63704C13.0617 4.55595 12.8774 4.49619 12.688 4.45904C12.6126 4.44659 12.5363 4.4409 12.46 4.44204M12.278 8.37904H12.178V5.31504H12.191C12.3971 5.29132 12.6057 5.32851 12.791 5.42204C12.9266 5.53036 13.0372 5.66684 13.115 5.82204C13.1989 5.98537 13.2473 6.16464 13.257 6.34804C13.266 6.56804 13.257 6.74804 13.257 6.89704C13.261 7.06867 13.25 7.24034 13.224 7.41004C13.1931 7.58427 13.1362 7.75284 13.055 7.91004C12.9631 8.05619 12.8389 8.17934 12.692 8.27004C12.5686 8.34983 12.4225 8.38706 12.276 8.37604M17.353 4.46104H14.923V9.26104H15.951V7.35704H17.251V6.46504H15.951V5.35304H17.351V4.46104"
        fill="white"
      />
    </svg>
  );
};
const AddIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0001 18.9582C5.05841 18.9582 1.04175 14.9415 1.04175 9.99984C1.04175 5.05817 5.05841 1.0415 10.0001 1.0415C14.9417 1.0415 18.9584 5.05817 18.9584 9.99984C18.9584 14.9415 14.9417 18.9582 10.0001 18.9582ZM10.0001 2.2915C5.75008 2.2915 2.29175 5.74984 2.29175 9.99984C2.29175 14.2498 5.75008 17.7082 10.0001 17.7082C14.2501 17.7082 17.7084 14.2498 17.7084 9.99984C17.7084 5.74984 14.2501 2.2915 10.0001 2.2915Z"
        fill="#DC143C"
      />
      <path
        d="M13.3334 10.625H6.66675C6.32508 10.625 6.04175 10.3417 6.04175 10C6.04175 9.65833 6.32508 9.375 6.66675 9.375H13.3334C13.6751 9.375 13.9584 9.65833 13.9584 10C13.9584 10.3417 13.6751 10.625 13.3334 10.625Z"
        fill="#DC143C"
      />
      <path
        d="M10 13.9582C9.65833 13.9582 9.375 13.6748 9.375 13.3332V6.6665C9.375 6.32484 9.65833 6.0415 10 6.0415C10.3417 6.0415 10.625 6.32484 10.625 6.6665V13.3332C10.625 13.6748 10.3417 13.9582 10 13.9582Z"
        fill="#DC143C"
      />
    </svg>
  );
};
const TrashIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="trash-lid">
        <path
          d="M17.3448 5.77722C14.6379 5.10713 11.8954 4.5666 9.14084 4.16747C7.50789 3.93086 5.863 3.77673 4.20615 3.70506L2.49982 3.62623"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.15681 3.44942L7.49479 2.39533C7.74027 1.63096 7.92408 1.05975 9.31786 1.2617L11.4786 1.57479C12.8724 1.77675 12.89 2.41082 12.9038 3.18749L12.9299 4.28592"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M15.7082 8.61621L15.1665 17.0079C15.0748 18.3162 14.9998 19.3329 12.6748 19.3329H7.32484C4.99984 19.3329 4.92484 18.3162 4.83317 17.0079L4.2915 8.61621"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.6084 14.75H11.3834"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.9165 11.417H12.0832"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const TickStatusIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z"
        fill="#00AD62"
      />
      <path
        d="M10.5799 16.0796C10.3799 16.0796 10.1899 15.9996 10.0499 15.8596L7.21994 13.0296C6.92994 12.7396 6.92994 12.2596 7.21994 11.9696C7.50994 11.6796 7.98994 11.6796 8.27994 11.9696L10.5799 14.2696L15.7199 9.12961C16.0099 8.83961 16.4899 8.83961 16.7799 9.12961C17.0699 9.41961 17.0699 9.89961 16.7799 10.1896L11.1099 15.8596C10.9699 15.9996 10.7799 16.0796 10.5799 16.0796Z"
        fill="#058D52"
      />
    </svg>
  );
};
const CloseIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z"
        fill="#E70000"
      />
      <path
        d="M13.0601 12.5004L15.3601 10.2004C15.6501 9.91035 15.6501 9.43035 15.3601 9.14035C15.0701 8.85035 14.5901 8.85035 14.3001 9.14035L12.0001 11.4404L9.70011 9.14035C9.41011 8.85035 8.93011 8.85035 8.64011 9.14035C8.35011 9.43035 8.35011 9.91035 8.64011 10.2004L10.9401 12.5004L8.64011 14.8004C8.35011 15.0904 8.35011 15.5704 8.64011 15.8604C8.79011 16.0104 8.98011 16.0804 9.17011 16.0804C9.36011 16.0804 9.55011 16.0104 9.70011 15.8604L12.0001 13.5604L14.3001 15.8604C14.4501 16.0104 14.6401 16.0804 14.8301 16.0804C15.0201 16.0804 15.2101 16.0104 15.3601 15.8604C15.6501 15.5704 15.6501 15.0904 15.3601 14.8004L13.0601 12.5004Z"
        fill="#E70000"
      />
    </svg>
  );
};
const WarningIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M21.08 9.08003V15.92C21.08 17.04 20.4799 18.08 19.5099 18.65L13.5699 22.08C12.5999 22.64 11.3999 22.64 10.4199 22.08L4.47992 18.65C3.50992 18.09 2.90991 17.05 2.90991 15.92V9.08003C2.90991 7.96003 3.50992 6.91999 4.47992 6.34999L10.4199 2.92C11.3899 2.36 12.5899 2.36 13.5699 2.92L19.5099 6.34999C20.4799 6.91999 21.08 7.95003 21.08 9.08003Z"
        fill="#E89B28"
      />
      <path
        d="M12 14.25C11.59 14.25 11.25 13.91 11.25 13.5V8.25C11.25 7.84 11.59 7.5 12 7.5C12.41 7.5 12.75 7.84 12.75 8.25V13.5C12.75 13.91 12.41 14.25 12 14.25Z"
        fill="#D58207"
      />
      <path
        d="M12 17.7499C11.87 17.7499 11.74 17.7198 11.62 17.6698C11.49 17.6198 11.39 17.5499 11.29 17.4599C11.2 17.3599 11.13 17.2499 11.07 17.1299C11.02 17.0099 11 16.8799 11 16.7499C11 16.4899 11.1 16.2298 11.29 16.0398C11.39 15.9498 11.49 15.8799 11.62 15.8299C11.99 15.6699 12.43 15.7598 12.71 16.0398C12.8 16.1398 12.87 16.2399 12.92 16.3699C12.97 16.4899 13 16.6199 13 16.7499C13 16.8799 12.97 17.0099 12.92 17.1299C12.87 17.2499 12.8 17.3599 12.71 17.4599C12.52 17.6499 12.27 17.7499 12 17.7499Z"
        fill="#D58207"
      />
    </svg>
  );
};
const ExportIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.32 10.8722C9.51 10.8722 9.7 10.9422 9.85 11.0922L11.88 13.1222L13.91 11.0922C14.2 10.8022 14.68 10.8022 14.97 11.0922C15.26 11.3822 15.26 11.8622 14.97 12.1522L12.41 14.7122C12.12 15.0022 11.64 15.0022 11.35 14.7122L8.79 12.1522C8.5 11.8622 8.5 11.3822 8.79 11.0922C8.94 10.9422 9.13 10.8722 9.32 10.8722Z"
        fill="white"
      />
      <path
        d="M11.8799 3.1923C12.2899 3.1923 12.6299 3.5323 12.6299 3.9423L12.6299 14.1123C12.6299 14.5223 12.2899 14.8623 11.8799 14.8623C11.4699 14.8623 11.1299 14.5223 11.1299 14.1123L11.1299 3.9423C11.1299 3.5223 11.4699 3.1923 11.8799 3.1923Z"
        fill="white"
      />
      <path
        d="M12 20.75C6.85 20.75 3.25 17.15 3.25 12C3.25 11.59 3.59 11.25 4 11.25C4.41 11.25 4.75 11.59 4.75 12C4.75 16.27 7.73 19.25 12 19.25C16.27 19.25 19.25 16.27 19.25 12C19.25 11.59 19.59 11.25 20 11.25C20.41 11.25 20.75 11.59 20.75 12C20.75 17.15 17.15 20.75 12 20.75Z"
        fill="white"
      />
    </svg>
  );
};
const InfoIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99996 18.9587C5.05829 18.9587 1.04163 14.942 1.04163 10.0003C1.04163 5.05866 5.05829 1.04199 9.99996 1.04199C14.9416 1.04199 18.9583 5.05866 18.9583 10.0003C18.9583 14.942 14.9416 18.9587 9.99996 18.9587ZM9.99996 2.29199C5.74996 2.29199 2.29163 5.75033 2.29163 10.0003C2.29163 14.2503 5.74996 17.7087 9.99996 17.7087C14.25 17.7087 17.7083 14.2503 17.7083 10.0003C17.7083 5.75033 14.25 2.29199 9.99996 2.29199Z"
        fill="#CF0830"
      />
      <path
        d="M9.99989 11.7588C9.65822 11.7588 9.37489 11.4755 9.37489 11.1338V10.9588C9.37489 9.99215 10.0832 9.51714 10.3499 9.33381C10.6582 9.12548 10.7582 8.98382 10.7582 8.76715C10.7582 8.35048 10.4166 8.00879 9.99989 8.00879C9.58322 8.00879 9.24158 8.35048 9.24158 8.76715C9.24158 9.10882 8.95824 9.39215 8.61658 9.39215C8.27491 9.39215 7.99158 9.10882 7.99158 8.76715C7.99158 7.65882 8.89155 6.75879 9.99989 6.75879C11.1082 6.75879 12.0082 7.65882 12.0082 8.76715C12.0082 9.71715 11.3082 10.1921 11.0499 10.3671C10.7249 10.5838 10.6249 10.7255 10.6249 10.9588V11.1338C10.6249 11.4838 10.3416 11.7588 9.99989 11.7588Z"
        fill="#CF0830"
      />
      <path
        d="M10 13.833C9.65 13.833 9.375 13.5497 9.375 13.208C9.375 12.8663 9.65833 12.583 10 12.583C10.3417 12.583 10.625 12.8663 10.625 13.208C10.625 13.5497 10.35 13.833 10 13.833Z"
        fill="#CF0830"
      />
    </svg>
  );
};
const AlertIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 150 137"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.09356 112.658C-0.746176 117.707 -0.693824 123.882 2.23113 128.881C5.15612 133.884 10.5136 136.955 16.3046 136.955H133.695C139.486 136.955 144.844 133.884 147.769 128.881C150.694 123.882 150.746 117.707 147.906 112.658L89.2111 8.31183C86.3221 3.17598 80.8894 0 75.0001 0C69.1107 0 63.678 3.17598 60.7891 8.31183L2.09356 112.658ZM7.77751 115.854L66.4729 11.5077C68.2078 8.42978 71.4645 6.52134 75 6.52134C78.5354 6.52134 81.7921 8.42981 83.527 11.5077L142.222 115.854C143.925 118.884 143.895 122.591 142.141 125.591C140.387 128.591 137.172 130.434 133.695 130.434H16.3046C12.8281 130.434 9.61301 128.591 7.85942 125.591C6.1049 122.592 6.07539 118.884 7.77751 115.854Z"
        fill="currentColor"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.9999 97.8251C69.6 97.8251 65.2174 102.208 65.2174 107.608C65.2174 113.008 69.6 117.39 74.9999 117.39C80.3999 117.39 84.7825 113.008 84.7825 107.608C84.7825 102.208 80.3999 97.8251 74.9999 97.8251ZM74.9999 104.346C76.8003 104.346 78.2612 105.807 78.2612 107.608C78.2612 109.408 76.8003 110.869 74.9999 110.869C73.1996 110.869 71.7387 109.408 71.7387 107.608C71.7387 105.807 73.1996 104.346 74.9999 104.346Z"
        fill="currentColor"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.9999 32.6089C69.5966 32.6089 65.2174 36.9915 65.2174 42.3915V81.5218C65.2174 86.9251 69.5966 91.3043 74.9999 91.3043C80.4033 91.3043 84.7825 86.9251 84.7825 81.5218V42.3915C84.7825 36.9915 80.4033 32.6089 74.9999 32.6089ZM74.9999 39.1303C73.1996 39.1303 71.7387 40.5911 71.7387 42.3915V81.5218C71.7387 83.3211 73.1996 84.7819 74.9999 84.7819C76.8003 84.7819 78.2612 83.321 78.2612 81.5218V42.3915C78.2612 40.5911 76.8003 39.1303 74.9999 39.1303Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default {
  UploadIcon,
  PdfIcon,
  AddIcon,
  TrashIcon,
  TickStatusIcon,
  CloseIcon,
  WarningIcon,
  ExportIcon,
  InfoIcon,
  AlertIcon,
};
