import { Table } from "antd";

const AccessibleTable = (props) => {
  const pagination = {
    pageSize: 5, // number of items per page
    total: props.rows.length, // total number of items
    showSizeChanger: false, // allows user to change the number of items per page
    showQuickJumper: false, // allows user to jump to a specific page
  };

  if (!props.rows) {
    return null; // or return a placeholder component, such as a loading spinner
  }

  return (
    <Table
      columns={props.columns}
      dataSource={props.rows.map((row, index) => ({ ...row, key: index }))}
      pagination={pagination}
      title={props.title}
    />
  );
};

export default AccessibleTable;
