import React from "react";
import { useNavigate } from "react-router-dom";
import RightArrow from "./assets/images/ArrowForward.svg";
import LandingPageImage from "./assets/images/LandingPageImage.svg";
import PaySlipLogo from "./assets/images/PaySlipLogo.svg";
import FooterLogo from "./assets/images/FooterLogo.svg";
import LandingPageBg from "./assets/images/LandingPageBg.svg";
const LandingPage = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/upload");
  };

  return (
    <div className="h-screen overflow-hidden bg-black flex items-center pl-20 pt-13 pr-12 pb-10 relative landing-page">
      <div className="w-full flex items-center justify-center z-10 image-animation">
        <img src={LandingPageImage} alt="system" />
      </div>
      <div className="shrink-0 flex-grow-0 basis-[520px] z-10 mt-[100px]">
        <div className="w-[203px] h-[106px] overflow-hidden top-13 absolute">
          <img src={PaySlipLogo} alt="Logo" className="w-full h-full" />
        </div>
        <div className="mt-12.5">
          <h1 className="font-bold text-5xl leading-[58px] text-white mb-[24px]">
            Document Processing Platform
          </h1>
          <p className="font-medium text-lg leading-[27px] text-grey400 mb-[16px]">
            Extracts information from various documents like pay slips and
            evaluates the applicant's eligibility for loan approval by the
            lender.
          </p>
          <p className="font-medium text-lg leading-[27px] text-grey400 mb-[32px]">
            Customizable rule-engine and texts for extraction
          </p>
          <button
            className="flex items-center rounded-lg py-3 px-10 bg-red200 border-none"
            onClick={handleClick}
          >
            <p className="font-semibold text-lg text-white mr-2">Try Demo</p>
            <img src={RightArrow} alt="right arrow" />
          </button>
        </div>
        <div className=" flex items-center justify-end fixed bottom-[40px] w-[520px]">
          <div className="w-[185px] h-[34px] overflow-hidden">
            <img src={FooterLogo} alt="footer-logo" w-full="true" h-full="true" />
          </div>
        </div>
      </div>
      <div className="absolute top-0 right-[124px]">
        <img src={LandingPageBg} alt="bg-img" />
      </div>
    </div>
  );
};
export default LandingPage;
