import React from "react";
import Logo from "../assets/images/Logo.svg";
import CompanyLogo from "../assets/images/ExperionLogo.svg";
import BgDesign from "../assets/images/Wavedesign.svg";

const Header = (props) => {

  const handleLogoClick  = () => {
    props.onLogoClick();
  };

  return (
    <div className="w-full h-24 flex items-center justify-between bg-black py-[17px] px-14 relative z-10">
      <div className="w-[117px] h-[61px] overflow-hidden">
        <img
          src={Logo}
          alt="Payslip generator Logo"
          className="h-full w-full"
          onClick={props.onLogoClick ? handleLogoClick : undefined}
          style={{ cursor: props.onLogoClick ? "pointer" : "default" }}
        />
      </div>
      <div className="w-[212px] h-[38px] overflow-hidden">
        <img src={CompanyLogo} alt="Company Logo" className="h-full w-full" />
      </div>
      <div className="absolute right-0 top-0">
        <img src={BgDesign} alt="background-design" className="h-full w-full" />
      </div>
    </div>
  );
};

export default Header;
