import React, { useState } from "react";
import { Modal, Input, Button } from "antd";

const ModalTextInput = ({ isOpen, onClose, onSave }) => {
  const [selectedWord, setSelectedWord] = useState("");

  const handleNameChange = (value) => {
    setSelectedWord(value);
  };

  const handleSave = () => {
    onSave(selectedWord);
    setSelectedWord("");
    onClose();
  };

  const handleClose = () => {
    setSelectedWord("");
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      footer={null}
      title="Enter selected word"
      centered
      className="Modal-container"
      style={{ width: "400px" }}
    >
      <div className="px-6 pt-[14px] pb-[17px]">
        <div className="mb-[42px]">
          <label
            htmlFor="text"
            className="mb-[5px] text-xs leading-6 font-medium text-black100"
          >
            Text
          </label>
          <Input
            value={selectedWord}
            id="text"
            autoComplete="off"
            onChange={(e) => handleNameChange(e.target.value.toUpperCase())}
            className="border-[1.5px] border-solid border-white200 p-2 text-xs leading-6 text-grey200 hover:!border-black100 hover:!shadow-none focus:!border-black100 focus:!shadow-none"
          />
        </div>
        <div className="flex items-start justify-end">
          <Button
            onClick={handleClose}
            className="py-2 !px-5 bg-white font-normal text-sm leading-[17px] text-black100 rounded border border-solid border-border400 h-full mr-[8px] hover:!text-black100 hover:!border-black100"
          >
            Close
          </Button>
          <Button
            onClick={handleSave}
            className="py-2 !px-5 bg-black font-normal text-sm leading-[17px] text-white rounded h-full hover:!text-white hover:!border-transparent"
            disabled={selectedWord === ""}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalTextInput;
