import React from "react";
import Icons from "../assets/icons";

const ToolTip = (props) => {
  if (!props.rows) {
    return null;
  } else {
    return (
      <table>
        <tbody>
          {Object.keys(props.rows).map((key, index) => (
            <tr className="font-medium text-xs leading-[15px] text-black100" key={index}>
              <td>{props.rows[key].type}</td>
              <td>
                {props.rows[key].eligibility === 0 && <Icons.CloseIcon />}
                {props.rows[key].eligibility === 2 && <Icons.WarningIcon />}
                </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
};

export default ToolTip;
