import React from "react";

import { Tag, Tabs } from "antd";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

import AccessibleTable from "./Table";
import ExcelDownloadButton from "./ExcelExportComponent";
import Icons from "../assets/icons";
import ToolTip from "./ToolTip";
import ErrorPayslipImage from "../assets/images/ErrorPayslip.svg";

const FileDetails = (props) => {
  const incrementKey = () => {
    props.incrementKey();
  };

  const decrementKey = () => {
    props.decrementKey();
  };

  const conditionTablecolumns = [
    {
      title: "WORD",
      dataIndex: "type",
      key: "type",
      render: (text) => <Tag color="geekblue">{text}</Tag>,
      width: 100,
    },
    {
      title: "EXTRACTED VALUE",
      dataIndex: "extracted_value",
      key: "extracted_value",
      width: 100,
    },
    {
      title: "MINIMUM VALUE",
      dataIndex: "required_value",
      key: "required_value",
      width: 100,
    },
    {
      title: "CONFIDENCE",
      dataIndex: "confidence",
      key: "confidence",
      width: 100,
    },
    {
      title: "",
      dataIndex: "eligibility",
      key: "eligibility",
      width: 50,
      render: (eligibility) => {
        if (eligibility === 1) {
          return <Icons.TickStatusIcon />;
        } else if (eligibility === 0) {
          return <Icons.CloseIcon />;
        } else {
          return <Icons.WarningIcon />;
        }
      },
    },
  ];

  const extractedWordsColumns = [
    {
      title: "WORD",
      dataIndex: "type",
      key: "type",
      render: (text) => <Tag color="orange">{text}</Tag>,
      width: 125,
    },
    {
      title: "EXTRACTED VALUE",
      dataIndex: "extracted_value",
      key: "extracted_value",
      width: 200,
    },
    {
      title: "CONFIDENCE",
      dataIndex: "confidence",
      key: "confidence",
      width: 125,
    },
    {
      title: "",
      dataIndex: "eligibility",
      key: "eligibility",
      width: 50,
      render: (eligibility) => {
        if (eligibility === 1) {
          return <Icons.TickStatusIcon />;
        } else if (eligibility === 0) {
          return <Icons.CloseIcon />;
        } else {
          return <Icons.WarningIcon />;
        }
      },
    },
  ];

  const DocsInfoColumns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => <Tag color="orange">{text}</Tag>,
      width: 250,
    },
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      width: 250,
    },
  ];

  let { conditions, words, info, jobstatus, eligible, id } = props.result;
  let processingCompleted = props.processing;
  conditions = JSON.parse(conditions);
  words = JSON.parse(words);
  info = JSON.parse(info);

  let data = { info }.info;
  let conditionExtractedData = [];
  let wordExtractedData = [];
  let issuesDataTable = {};
  let wordExtractedDataKeyArray = [];
  let batchDocInfo = [];
  let issuesData = { denied: 0, hold: 0, combined_issue: 0 };
  const regExp = /[^0-9.]/g;

  //Adding data for conditional extraction table
  for (let key in conditions) {
    if (key in data) {
      let condition =
        Number(data[key][0].replace(regExp, "")) > Number(conditions[key])
          ? 1
          : 0;
      if (condition === 0) {
        issuesData.combined_issue++;
        issuesDataTable = {
          ...issuesDataTable,
          [key]: {
            type: key,
            eligibility: condition,
          },
        };
      }
      conditionExtractedData.push({
        type: key,
        extracted_value: data[key][0].replace(regExp, ""),
        required_value: conditions[key],
        confidence: data[key][1].toFixed(2),
        eligibility: condition,
      });
    } else {
      conditionExtractedData.push({
        type: key,
        extracted_value: "",
        required_value: conditions[key],
        confidence: "",
        eligibility: 2,
      });
      issuesDataTable = {
        ...issuesDataTable,
        [key]: {
          type: key,
          eligibility: 2,
        },
      };
      issuesData.combined_issue++;
    }
  }

  //Adding data for word extraction table
  for (let key2 in words) {
    if (key2 in data) {
      wordExtractedData.push({
        type: key2,
        extracted_value: data[key2][0],
        confidence: data[key2][1].toFixed(2),
        eligibility: 1,
      });
      wordExtractedDataKeyArray.push(key2);
    } else {
      wordExtractedData.push({
        type: key2,
        extracted_value: "",
        confidence: "",
        eligibility: 2,
      });
    }
  }
  for (let key3 in data) {
    if (!wordExtractedDataKeyArray.includes(key3)) {
      wordExtractedData.push({
        type: key3,
        extracted_value: data[key3][0],
        confidence: data[key3][1].toFixed(2),
        eligibility: 1,
      });
    }
  }

  batchDocInfo.push({
    type: "Current File Number",
    number: props.fileNumber,
  });

  for (let key4 in props.fileInfo) {
    batchDocInfo.push({
      type: key4,
      number: props.fileInfo[key4],
    });
  }

  batchDocInfo.push({
    type: "Text Extraction Status",
    number: props.processing ? "Completed" : "In progress",
  });

  return (
    <div>
      {jobstatus === "NOT ACCEPTED" && (
        <div className="flex flex-col items-center justify-center h-[calc(100vh-96px)]">
          <img
            src={ErrorPayslipImage}
            alt="error-payslip"
            className="w-[80px] mb-[20px]"
          />
          <div>
            <h2 className="font-semibold text-2xl">Payslip Not Accepted</h2>
          </div>
        </div>
      )}
      {jobstatus === "SUCCEEDED" && (
        <div className="flex">
          <div className="w-full h-[calc(100vh-95px)]">
            <iframe
              style={{ width: "100%", height: "85%" }}
              src={props.result.payslip_url}
              alt="Payslip"
              title="Payslip"
            >
              Uploaded Payslip
            </iframe>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                className={`mr-4 mt-4 cursor-pointer p-2 text-sm leading-[14px] text-white font-Inter rounded bg-red200 ${props.buttonDisabled.startDisabled ? 'opacity-50' : ''}`}
                id="prevBtn"
                onClick={decrementKey}
                disabled= {props.buttonDisabled.startDisabled}
              >
                Prev
              </button>

              <button
                className={`mr-4 mt-4 cursor-pointer p-2 text-sm leading-[14px] text-white font-Inter rounded bg-red200 ${props.buttonDisabled.endDisabled ? 'opacity-50' : ''}`}
                id="prevBtn"
                onClick={incrementKey}
                disabled= {props.buttonDisabled.endDisabled}
              > 
                Next
              </button>
            </div>
          </div>

          <div className="w-[701px] flex-grow-0 flex-shrink-0 basis-[701px]  border-l border-solid border-border100">
            <div className="pt-6   pb-10 bg-white">
              <h2 className="font-bold text-2xl leading-7.5 mb-[32px] pl-8 pr-10">
                Payslip Insights
              </h2>
              <div>
                <Tabs
                  className="tabs-Details"
                  defaultActiveKey="1"
                  items={[
                    {
                      label: "Conditional Extraction",
                      key: "1",
                      children: (
                        <div className="h-[calc(100vh-318px)] overflow-auto pb-13 pr-10">
                          <div className="extraction-table mb-[40px]">
                            <AccessibleTable
                              rows={conditionExtractedData}
                              columns={conditionTablecolumns}
                            />
                          </div>

                          <div style={{ display: "flex", gap: "8px" }}>
                            {processingCompleted && (
                              <ExcelDownloadButton
                                apiEndpoint={`http://54.158.196.125:8000/api/payslip/download-batch-data/${id}`}
                                fileName="payslip-extractions.xlsx"
                                text="Export batch text extraction"
                              />
                            )}

                            {id && (
                              <ExcelDownloadButton
                                apiEndpoint={`http://54.158.196.125:8000/api/payslip/download-data/${id}`}
                                fileName={`${id}-payslip-extractions.xlsx`}
                                text="Export current file extraction"
                              />
                            )}
                          </div>
                          <div className="fixed bottom-0  pl-8  bg-white border-t border-solid border-border500 w-full max-w-[701px] flex-grow-0 flex-shrink-0 basis-[701px]">
                            {jobstatus === "SUCCEEDED" ? (
                              eligible === 1 ? (
                                <div className="flex items-center justify-between w-full ">
                                  <div className="py-7 pr-13 flex items-center">
                                    <p className="mr-2 text-grey300 text-xs leading-4 font-medium">
                                      No issues found
                                    </p>
                                  </div>
                                  <div className="py-7 px-13 flex items-center bg-green100">
                                    <Icons.TickStatusIcon />
                                    <p className="ml-2.5 font-medium text-base leading-[19px] text-green200">
                                      Approved
                                    </p>
                                  </div>
                                </div>
                              ) : eligible === 0 ? (
                                <div className="flex items-center justify-between w-full ">
                                  <div className="py-7 pr-13 flex items-center">
                                    <p className="mr-2 text-grey300 text-xs leading-4 font-medium">
                                      {issuesData.combined_issue} issues found
                                    </p>

                                    {issuesDataTable && (
                                      <Tippy
                                        content={
                                          <ToolTip rows={issuesDataTable} />
                                        }
                                      >
                                        <div className="cursor-pointer">
                                          <Icons.InfoIcon />
                                        </div>
                                      </Tippy>
                                    )}
                                  </div>
                                  <div className="py-7 px-13 flex items-center bg-red700">
                                    <Icons.CloseIcon />
                                    <p className="ml-2.5 font-medium text-base leading-[19px] text-red600">
                                      Denied
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <div className="flex items-center justify-between w-full ">
                                  <div className="my-7 pr-13 flex items-center">
                                    <p className="mr-2 text-grey300 text-xs leading-4 font-medium">
                                      {issuesData.combined_issue} issues found
                                    </p>

                                    {issuesDataTable && (
                                      <Tippy
                                        content={
                                          <ToolTip rows={issuesDataTable} />
                                        }
                                      >
                                        <div className="cursor-pointer">
                                          <Icons.InfoIcon />
                                        </div>
                                      </Tippy>
                                    )}
                                  </div>
                                  <div className="py-7 px-13 flex items-center bg-yellow100">
                                    <Icons.WarningIcon />
                                    <p className="ml-2.5 font-medium text-base leading-[19px] text-yellowDark">
                                      On Hold
                                    </p>
                                  </div>
                                </div>
                              )
                            ) : null}
                          </div>
                        </div>
                      ),
                    },
                    {
                      label: "Text Extraction",
                      key: "2",
                      children: (
                        <div className="h-[calc(100vh-235px)] overflow-auto pb-13 pr-10">
                          <div className="extraction-table mb-[40px]">
                            <AccessibleTable
                              rows={wordExtractedData}
                              columns={extractedWordsColumns}
                            />
                          </div>
                          <div style={{ display: "flex", gap: "8px" }}>
                            {processingCompleted && (
                              <ExcelDownloadButton
                                apiEndpoint={`http://54.158.196.125:8000/api/payslip/download-batch-data/${id}`}
                                fileName="payslip-extractions.xlsx"
                                text="Export batch text extraction"
                              />
                            )}

                            {id && (
                              <ExcelDownloadButton
                                apiEndpoint={`http://54.158.196.125:8000/api/payslip/download-data/${id}`}
                                fileName={`${id}-payslip-extractions.xlsx`}
                                text="Export current file extraction"
                              />
                            )}
                          </div>
                        </div>
                      ),
                    },
                    {
                      label: " Processing Information",
                      key: "3",
                      children: (
                        <div className="h-[calc(100vh-235px)] overflow-auto pb-13 pr-10">
                          <div className="extraction-table mb-[40px]">
                            <AccessibleTable
                              rows={batchDocInfo}
                              columns={DocsInfoColumns}
                            />
                          </div>
                          <div style={{ display: "flex", gap: "8px" }}>
                            {processingCompleted && (
                              <ExcelDownloadButton
                                apiEndpoint={`http://54.158.196.125:8000/api/payslip/download-batch-data/${id}`}
                                fileName="payslip-extractions.xlsx"
                                text="Export batch text extraction"
                              />
                            )}

                            {id && (
                              <ExcelDownloadButton
                                apiEndpoint={`http://54.158.196.125:8000/api/payslip/download-data/${id}`}
                                fileName={`${id}-payslip-extractions.xlsx`}
                                text="Export current file extraction"
                              />
                            )}
                          </div>
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileDetails;
