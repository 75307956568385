import "./App.css";
import "./assets/css/global-style.scss";
import { Route, Routes, BrowserRouter } from "react-router-dom";

import FileUpload from "./FileUpload";
import LandingPage from "./LandingPage";
import Results from "./Results";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />}></Route>
        <Route path="/upload" element={<FileUpload />}></Route>
        <Route path="/details" element={<Results />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
